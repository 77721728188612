function getSupportedDays(timetableMapData, number, lang) {
    const weekDaysLang = {
        "en": ["Sun & Public Holidays", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "tc": ["星期日 & 公眾假期", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
        "sc": ["星期日 & 公众假期", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
    }

    const delimeter = {
        "en": " - ",
        "tc": " 至 ",
        "sc": " 至 "
    }
    
    const supportValues = timetableMapData[number];

    if (!supportValues) {
        return ""; // Handle case where number does not exist
    }

    const supportedDays = [];

    // Collect supported days
    for (let i = 0; i < supportValues.length; i++) {
        if (supportValues[i] === "1") {
            supportedDays.push(weekDaysLang[lang][i]);
        }
    }

    // Format the output based on supported days
    if (supportedDays.length === 0) {
        return ""; // No days supported
    } else if (supportedDays.length === 1) {
        return supportedDays[0]; // Only one day supported
    } else {
        // Determine the range of supported days
        const startDay = supportedDays[0];
        const endDay = supportedDays[supportedDays.length - 1];

        // Return formatted range
        if (startDay === endDay) {
            return startDay; // If start and end are the same
        } else {
            const delimiter = delimeter[lang]
            return `${startDay}${delimiter}${endDay}`; // Return range
        }
    }
}

export const timetableMap = {
    getTimeRange: (timetableMapData, serviceId, lang) => {
        return getSupportedDays(timetableMapData, serviceId, lang)
    },
    formatTime:(input) => {
        // Check if the input is a valid string of length 4
        if (typeof input === 'string' && input.length === 4) {
            const hours = input.slice(0, 2); // Get the first two characters
            const minutes = input.slice(2);    // Get the last two characters
            return `${hours}:${minutes}`;       // Format the output
        } else {
            return ""; // Handle invalid input
        }
    },
    toMinutes: (second) => {
        const number = parseInt(second)
        return number/60
    }
}
