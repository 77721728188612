import {useNavigate, useLocation} from "react-router-dom";
import LineDetailHeader from "../components/LineDetail/LineDetailHeader";
import {useContext, useEffect, useState} from "react";
import haversine from "haversine";
import {findNearest} from "geolib";
import {BusCollectionProvider} from "../store/BusCollection";
import DbContext from "../context/DbContext";
import {api} from "../libs/api";
import CTBStations from "../components/LineDetail/CTBStations";
import KMBStations from "../components/LineDetail/KMBStations";
import GMBStations from "../components/LineDetail/GMBStations";
import {useTranslation} from "react-i18next";
import {location} from "../libs/location";
import NLBStations from "../components/LineDetail/NLBStations";
import {busColor} from "../utils/busColor";
import BuddyFonts from "../components/fonts/BuddyFonts";
import {Drawer} from "@material-tailwind/react";
import {timetableMap} from "../libs/timetableMap";
import BorderGradientButton from '../components/buttons/BorderGradientButton';
function LineDetail() {
    const { t, i18n} = useTranslation();
    const locationUtil = useLocation();
    const query = new URLSearchParams(locationUtil.search);
    const co = query.get('co');
    const route = query.get('route');
    const direction = query.get('direction');
    const destination = query.get('destination');
    const routeId = query.get("routeId"); // for nlb bus only
    const seq = query.get("defaultSeq"); // for nlb bus only
    const gtfsId = query.get("gtfsId");
    const serviceType = query.get("serviceType");
    const lang = i18n.language
    
    const dbContext = useContext(DbContext)
    const timetableMapData = dbContext.timetableMap
    let scheduleData = {}

    if(co === "KMB" && gtfsId !== null){
        if (direction === "O"){
            const schedule = dbContext.timetable.KMB[gtfsId]["1"]
            scheduleData = getScheduleDetail(schedule);
        }else{
            const schedule = dbContext.timetable.KMB[gtfsId]["2"]
            scheduleData = getScheduleDetail(schedule);
        }
    }

    // const [busArrivalTime, setBusArrivalTime] = useState([]);
    const [stopList, setStopList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [closedStop, setClosedStop] = useState({seq: "1"});
    const loadingMap = Array(10).fill(0);
    const [error, setError] = useState(null);
    const [openScheduleDrawer, setOpenScheduleDrawer] = useState(false)
    const clickCloseScheduleDrawer = () => setOpenScheduleDrawer(false)
    const clickOpenScheduleDrawer = () => setOpenScheduleDrawer(true)

    useEffect(()=>{
        const stopDB = dbContext.stopDB
        if (stopDB == null){
            return
        }
        const fetchData = async () => {
            try{
                let initFares = {};
                if (gtfsId !== null){
                    if (dbContext.fares[co] && dbContext.fares[co][gtfsId]){
                        const fares = dbContext.fares[co][gtfsId];
                        if (direction === "O"){
                            if (fares["1"] !== undefined){
                                initFares = fares["1"];
                            }
                        }else{
                            if (fares["2"] !== undefined){
                                initFares = fares["2"];
                            }
                        }
                    }
                }
                let stopSeq
                if (co === "NLB"){
                    stopSeq = await api.getNLBStopList(route, routeId, direction, co)
                } else if (co === "KMB") {
                    stopSeq = await api.getKMBStopList(route, direction, serviceType, co)
                } else {
                    stopSeq = await api.getCoStopList(route, direction ,co)
                }
                const localStopList = stopSeq.map(stop => {
                    const key = co + '-' + stop.stopId
                    if (stopDB.hasOwnProperty(key)) {
                        let obj =  {
                            bound: stop.bound,
                            co: stop.co,
                            route: stop.route,
                            seq: stop.seq,
                            name: stopDB[key].name,
                            stopId: stop.stopId,
                            lat: stopDB[key].lat,
                            long: stopDB[key].long,
                            routeId: stop.routeId ? stop.routeId : ""  // only for gms bus
                        };

                        if (initFares.hasOwnProperty(stop.seq)){
                            obj.fares = initFares[stop.seq];
                        }

                        return obj;
                    }
                    return {}
                });
                setStopList(localStopList)
                // setBusArrivalTime(eta)
                // console.log(busEtaList.data)
                // get current position
                if (seq) {
                    setClosedStop({seq: seq});
                }else{
                    const currentLocation = location.getCurrentLocation();
                    // let minDistance = Infinity;
                    let closedStop = {};
                    const rangeInMeters = 10;
                    const parsedLocations = localStopList.filter((stop) => {
                        const location = {latitude: parseFloat(stop.lat), longitude: parseFloat(stop.long)}
                        const distance = haversine(currentLocation, location);
                        return distance < rangeInMeters;
                    }).map((stop) => {
                        return {latitude: parseFloat(stop.lat), longitude: parseFloat(stop.long), stop: stop};
                    })
                    if (parsedLocations.length > 0){
                        const closedLocation = findNearest(currentLocation, parsedLocations);
                        closedStop = closedLocation.stop;
                        setClosedStop({seq: closedStop.seq});
                    }
                }

                setLoading(false)

            }catch (error){
                // use deny locationUtil permission
                if (error.code === 1) {
                    setClosedStop({seq: "1"});
                    setLoading(false)
                }else {
                    throw error
                    //setError(error)
                    // console.error(error);
                    // setLoading(true);
                }
            }
        }
        if (isLoading){
            fetchData().catch(setError)
        }
    }, [route, routeId, direction, co, isLoading, dbContext, seq, gtfsId, serviceType])


    const navigate = useNavigate();

    function getScheduleDetail(schedule) {
        let scheduleData = {}
        for (const serviceId in schedule) {
            if (!scheduleData.hasOwnProperty(serviceId)) {
                scheduleData[serviceId] = [];
            }
            for (const calendar of schedule[serviceId]) {
                const from = timetableMap.formatTime(calendar[0]);
                const to = timetableMap.formatTime(calendar[1]);
                const mintueFrom = timetableMap.toMinutes(calendar[2]);
                const mintueTo = timetableMap.toMinutes(calendar[3]);
                let scheduleTimeRange
                if (mintueFrom === mintueTo){
                    scheduleTimeRange = `${mintueFrom}`
                }else{
                    scheduleTimeRange = `${mintueFrom}-${mintueTo}`
                }
                scheduleData[serviceId].push({ from, to, scheduleTimeRange });
            }
        }
        return scheduleData
    }

    function renderSchedule(serviceId, schedule){
        const timeRange = timetableMap.getTimeRange(timetableMapData, serviceId, lang);

        return (
            <div className="mt-6" key={serviceId}>
                <p className={`${BuddyFonts.SectionTitle} text-[#334155]`}>{timeRange}</p>
                <ul className="divide-y">
                    {schedule.map((item) => {
                        return <li key={serviceId + item.from + item.to + item.scheduleTimeRange} className="py-3">
                            <div className="flex flex-row w-full" key={serviceId + item.from + item.to + item.scheduleTimeRange}>
                                <span className={`${BuddyFonts.Body1} text-[#334155] font-semibold`}>{item.from}</span>
                                <span className={`${BuddyFonts.Body1} text-[#334155] font-semibold ml-3`}> - </span>
                                <span className={`${BuddyFonts.Body1} text-[#334155] font-semibold ml-3`}>{item.to}</span>
                                <span className={`${BuddyFonts.Body1} text-[#334155] font-semibold ml-auto`}>{item.scheduleTimeRange}</span>
                                <span className={`${BuddyFonts.Body1} text-[#6B7280] font-semibold ml-1`}>{t('minute_unit')}</span>
                            </div>
                            
                        </li>
                    })}
                </ul>
            </div>
        )
    }

    function backToPreviousPage(){
        navigate(-1)
    }

    function goToFeedback() {
        window.open("https://forms.gle/49yomXDNm4NUgtJG8", "_blank");
    }


    if (error) {
        throw error
    }

    function renderStation() {
        if (co === "CTB"){
            return <CTBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction}/>
        }else if (co === "KMB") {
            return <KMBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction}/>
        }else if (co === "GMB") {
            return <GMBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction}/>
        }else if (co === "NLB"){
            return <NLBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction} routeId={routeId}/>
        }
    }

    
    if (!isLoading){
        if (stopList.length === 0){
            return (
                <div className='flex flex-col'>
                <div className='sticky top-0 px-6 py-4 bg-white z-10 max-w-sm'>
                    <LineDetailHeader route={{route:route, name: stopList.length > 0 ? stopList.slice(-1)[0].name[lang] : destination}} co={co} backToPreviousPage={backToPreviousPage}/>
                    
                </div>
                <div className='h-px bg-[#000000] opacity-[.1] max-w-sm'></div>
                <div className='px-6 py-3 max-w-sm'>
                    <p className={`${BuddyFonts.Body1} text-font-757575 mb-3`}>{t('add_a_stop')}</p>
                    <div className="flex flex-col justify-center items-center mt-24">
                        <img src="/img/empty_state.png" alt="empty_stop_list" className="w-36"></img>
                        <p className={`${BuddyFonts.Body1} text-font-757575 mt-4`}>{t('bus_co_return_failed')}</p>
                        <BorderGradientButton className="mt-4 w-full" onClick={goToFeedback}>
                            <div className="h-full w-full flex justify-center items-center">
                                <p className="bg-gradient-to-tl from-from-blue to-to-blue border-from-blue text-transparent bg-clip-text text-sm font-bold uppercase ml-2 tracking-wider"
                                >{t('feedback')}</p>
                            </div>
                        </BorderGradientButton>
                    </div>

                </div>
                
                
            </div>
            )
        }

        return (
            <div className='flex flex-col'>
                <div className='sticky top-0 px-6 py-4 bg-white z-10'>
                    <LineDetailHeader route={{route:route, name: stopList.length > 0 ? stopList.slice(-1)[0].name[lang] : destination}} co={co} backToPreviousPage={backToPreviousPage}/>
                    {
                        Object.keys(scheduleData).length !== 0 && <div className="inline-flex flex-row justify-center items-center ml-3 mt-2 border rounded-lg px-2 py-1 bg-[#F4F4F4]" onClick={clickOpenScheduleDrawer}>
                        <i className="bg-clip-text text-transparent bg-gradient-to-tl from-from-blue to-to-blue border-from-blue fa-regular fa-clock"></i>
                        <span className="font-quickSand bg-clip-text text-transparent bg-gradient-to-tl from-from-blue to-to-blue border-from-blue text-xs ml-1">{t('scheudle_time')}</span>
                        <i className="bg-clip-text text-transparent bg-gradient-to-tl from-from-blue to-to-blue border-from-blue fa-solid fa-angle-right ml-1"></i>
                    </div>
                    }
                </div>
                <div className='h-px bg-[#000000] opacity-[.1]'></div>
                <div className='px-6 py-3'>
                    <p className={`${BuddyFonts.Body1} text-font-757575 mb-3`}>{t('add_a_stop')}</p>
                        <BusCollectionProvider>
                            {renderStation()}
                        </BusCollectionProvider>
                </div>
                
                <Drawer open={openScheduleDrawer} onClose={clickCloseScheduleDrawer} placement="bottom" size={700} overlay={false} className="overflow-auto">
                    <div className='flex flex-col p-6 h-screen m-auto'>
                        
                        <div className="flex flex-row">
                            <p className={`${BuddyFonts.SubPageTitle} font-normal grow text-[#334155]`}>{t('scheudle_time')}</p>
                            <p className={`${BuddyFonts.Body1} font-normal bg-clip-text text-transparent bg-gradient-to-tl from-from-blue to-to-blue border-from-blue`} onClick={clickCloseScheduleDrawer}>{t('close')}</p>
                        </div>

                        <div className='h-6'></div>
                        <div className='flex-col'>
                            {
                                Object.keys(scheduleData).length !== 0 && Object.keys(scheduleData).map((serviceId) => {
                                    return renderSchedule(serviceId, scheduleData[serviceId])
                                })
                            }
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    } else {
        // for loading page
        return (
            <div className='flex flex-col'>
                <div className='sticky top-0 p-4 bg-white z-10  max-w-sm'>
                    <LineDetailHeader route={{route: route, name: destination}} co={co} backToPreviousPage={backToPreviousPage}/>
                </div>
                <div className='p-4 max-w-sm'>
                    <div className='h-6'></div>
                    <p className={`${BuddyFonts.Body1} text-font-757575`}>{t('add_a_stop')}</p>
                    <div className='h-6'></div>
                    <div className='h-screen'>
                        <ul>{
                            loadingMap.map((_, index) => {
                                const roundedUp = index === 0 ? 'rounded-t-lg':''
                                const roundedBottom = index === loadingMap.length-1 ? 'rounded-b-lg':''
                                const lastOneHigh = index === loadingMap.length-1 ? 'max-h-5':''
                                return <li key={index}>
                                    <div className='flex flex-row'>
                                        <div className='relative w-full '>
                                            <div className={`absolute w-3.5 h-full ${busColor(co)} ${roundedUp} ${roundedBottom} ${lastOneHigh}`}></div>
                                            <div className={`absolute top-1 left-[3px] rounded-full bg-white w-2 h-2`}></div>
                                            <div className='ml-6 h-10 rounded-lg animate-pulse '>
                                                <div className='rounded-lg mt-2 h-10 bg-gray-300'>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            })
                        }</ul>

                    </div>
                </div>
            </div>

        )
    }
}

export default LineDetail
