import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import resources from "./locale/lang";

i18n.use(initReactI18next).init({
    resources,
    supportedLngs: ['en', 'tc', 'sc'],
    fallbackLng: 'tc',
    lng: localStorage.getItem("language") === null || localStorage.getItem("language") === "" ? "tc" : localStorage.getItem("language"),
    interpolation: {
        escapeValue: false
    }
});

export default i18n
