export function initLocalStorage() {
    const key = 'collections'
    if (!localStorage.getItem("dbVersion")){
        localStorage.removeItem(key)
    }
    console.log("init local storage")
}

export function setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

export function getItem(key) {
    return JSON.parse(localStorage.getItem(key))
}

export function isFavorite(route, stop) {
    const collections = getItem('collections')
    return collections.some(collection => collection.route === route && collection.stop === stop)
}

export function addFavorite(route, stop) {
    const collections = getItem('collections')
    collections.push({route, stop})
    setItem('collections', collections)
}

export function getFavoritesList() {
    return getItem('collections') ?? []
}
