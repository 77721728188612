const LOCATION_PERMISSION = "location_permission";
const LOCATION = "location";
export const location = {
    initLocation: (success, failed) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, failed);
        }
    },

    updateLocation: () => {
        const permit = localStorage.getItem(LOCATION_PERMISSION)
        if (permit === "true") {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{
                    const currentLocation = {latitude: position.coords.latitude, longitude: position.coords.longitude}
                    localStorage.setItem(LOCATION, JSON.stringify(currentLocation))
                    console.log("update user location")
                }, (err)=>{

                });
            }
        }
        console.log("update location")
    },
    getCurrentLocation: () => {
        const permit = localStorage.getItem(LOCATION_PERMISSION)
        const location = localStorage.getItem(LOCATION)
        if (permit === "true" && location) {
            return JSON.parse(location)
        }else {
            return {latitude: 0, longitude: 0}
        }
    },
    isAllowedLocation: () => {
        return localStorage.getItem(LOCATION_PERMISSION) === "true"
    }
}
