import {useCallback, useEffect, useState} from "react";
import {api} from "../../libs/api";
import Station from "../stations/Station";
import {useTranslation} from "react-i18next";
import ErrorPage from "../error/errorPage";

function NLBStations(props){
    const { i18n} = useTranslation();
    const stations = props.stopList
    const [selectedSeq, setSelectedSeq] = useState(props.defaultStop);
    const [busArrivalTime, setBusArrivalTime] = useState([]);
    const [error, setError] = useState(null);
    const route = props.route
    const routeId = props.routeId
    // const direction = props.direction
    const lang = i18n.language
    const callback = useCallback(async () => {
        const stop = stations.find(station => station.seq.toString() === selectedSeq.toString())
        try {
            const eta = await api.getNLBEtaData(routeId, stop.stopId)
            setBusArrivalTime(eta)
        }catch (e) {
            throw e
        }
    }, [routeId, stations, selectedSeq])

    useEffect(()=> {
        callback().catch(setError)
    }, [callback])

    useEffect(()=>{
        const internal = setInterval(()=>{
            callback().catch(setError)
        }, 10000);
        return () => {clearInterval(internal)};
    }, [callback])


    const handleClick = (station) => {
        api.getNLBEtaData(routeId, station.stopId).then((resp) => {
            setBusArrivalTime(resp)
        })
        setSelectedSeq(station.seq)
    };

    if (error) {
        console.log(error)
        return <ErrorPage/>
    }

    return <Station stations={stations}
                    busArrivalTime={busArrivalTime}
                    selectedSeq={selectedSeq}
                    handleClick={handleClick}
                    lang={lang}
                    route={route}
                    co="NLB"/>;
}

export default NLBStations
