import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {busColorWithTransparent} from "../../../utils/busColor";
import BuddyFonts from "../../../components/fonts/BuddyFonts";

function BusLineItem(props) {
    const { t, i18n} = useTranslation();
    const bgColor = props.incId % 2 === 0 ? '' : 'bg-searchBox-grey/[.04]'
    const navigate = useNavigate();
    const lang = i18n.language
    let busCoColor = busColorWithTransparent(props.route.co)

    let serviceType
    if (props.route.co === "KMB"){
        serviceType = props.route.serviceType.toString()
    }

    function toRouteDetail(route, direction){
        // co + route + direction + destination + serviceType + gftsId
        let url = `/${lang}/routeDetail?co=${props.route.co}&route=${route}&direction=${direction}&destination=${props.route.dest.en}`
        if (props.route.co === "NLB"){
            url = url + `&routeId=${props.route.route_id}`
        }
        if (props.route.gtfsId){
            url = url + `&gtfsId=${props.route.gtfsId}`
        }
        if (props.route.co === "KMB"){
            url = url + `&serviceType=${props.route.serviceType}`
        }

        navigate(url)
    }

    return (
        <div className='bg-white w-full ' onClick={()=>toRouteDetail(props.route.route, props.route.direction)}>
            <div className={`flex flex-col p-3 ${bgColor} w-full justify-center`}>
                <div className={`flex flex-row items-center`}>
                    <div className='pl-2'>
                        <span
                            className={`h-6 w-6 ${busCoColor} text-font-212121 font-quickSand font-bold py-1 px-3 rounded-lg text-base`}>{props.route.route}</span>
                    </div>
                    
                    <div className='w-full pl-2'>
                        <span className={`${BuddyFonts.Caption} text-[#757575]`}>To</span>
                        <span className="text-black font-quickSand pl-2 text-base">{props.route.dest[lang]}</span>
                    </div>
                </div>
                {
                    serviceType !== undefined && serviceType !== "1" && (
                        <div className={`flex flex-row items-center`}>
                            <div className='pl-2 invisible'>
                                <span
                                    className={`h-6 w-6 ${busCoColor} text-font-212121 font-quickSand font-bold py-1 px-3 rounded-lg text-base`}>{props.route.route}</span>
                            </div>
                            
                            <div className='pl-2'>
                                <span className={`${BuddyFonts.Caption} text-[#757575] invisible`}>To</span>
                                <span className={`pl-2 ${BuddyFonts.Caption} text-[#757575]`}>{t("special_service")}</span>
                            </div>
                        </div>
                    )
                }    
            </div>
            
            
        </div>
    )
}


export default BusLineItem
