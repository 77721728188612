import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Button, Drawer} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {location} from "../libs/location";

function Setting() {
    const {t} = useTranslation();
    const language = localStorage.getItem("language") === null || localStorage.getItem("language") === "" ? "" : localStorage.getItem("language")
    const [userSelectedLocationPermit, setUserSelectedLocationPermit] = useState(location.isAllowedLocation())
    // const [locationPermission, setLocationPermission] = useState(false)
    const navigate = useNavigate();
    // const [openDrawer, setOpenDrawer] = useState(false)

    const [openLocationDrawer, setOpenLocationDrawer] = useState(false)
    const clickLocationDrawer = () => setOpenLocationDrawer(!openLocationDrawer);
    // const clickCloseLocationDrawer = () => setOpenDrawer(false);
    function backToPreviousPage(){
        navigate(`/${language}`)
    }

    function selectedLocation(val){
        if (val === true){
            location.initLocation((position)=>{
                const currentLocation = {latitude: position.coords.latitude, longitude: position.coords.longitude}
                localStorage.setItem("location", JSON.stringify(currentLocation))
                localStorage.setItem("location_permission", "true")
                setUserSelectedLocationPermit(true)
            }, (err) => {
                localStorage.setItem("location_permission", "false")
                setUserSelectedLocationPermit(false)
            });
        }else{
            localStorage.setItem("location_permission", "false")
            setUserSelectedLocationPermit(false)
        }
        const per = localStorage.getItem("location_permission")
        if (per === null || per === "false"){
            // promt
            clickLocationDrawer()
        }
        clickLocationDrawer()
    }

  return (
      <div className='flex flex-col p-6 max-w-sm h-screen'>

          <div className='flex flex-row items-center' onClick={backToPreviousPage}>
              {/*header*/}
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <mask id="mask0_399_2137" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                          <rect x="24" y="24" width="24" height="24" transform="rotate(-180 24 24)" fill="#D9D9D9"/>
                      </mask>
                      <g mask="url(#mask0_399_2137)">
                          <path
                              d="M11.4 11.9992L15.3 15.8992C15.4833 16.0826 15.575 16.3159 15.575 16.5992C15.575 16.8826 15.4833 17.1159 15.3 17.2992C15.1166 17.4826 14.8833 17.5742 14.6 17.5742C14.3166 17.5742 14.0833 17.4826 13.9 17.2992L9.29995 12.6992C9.19995 12.5992 9.12912 12.4909 9.08745 12.3742C9.04578 12.2576 9.02495 12.1326 9.02495 11.9992C9.02495 11.8659 9.04578 11.7409 9.08745 11.6242C9.12912 11.5076 9.19995 11.3992 9.29995 11.2992L13.9 6.69922C14.0833 6.51589 14.3166 6.42422 14.6 6.42422C14.8833 6.42422 15.1166 6.51589 15.3 6.69922C15.4833 6.88255 15.575 7.11588 15.575 7.39922C15.575 7.68255 15.4833 7.91588 15.3 8.09922L11.4 11.9992Z"
                              fill="#1C1B1F"/>
                      </g>
                  </svg>
              </div>
              <p className='text-xl font-bold font-quickSand'>{t('setting')}</p>
          </div>
          <div className='h-6'></div>

          <div className='border-b border-[#E0E0E0]' onClick={clickLocationDrawer}>
              <div className='pt-6 pb-6 w-full flex flex-row items-center'>
                  <p className='text-xs font-medium tracking-wide font-quickSand text-[#757575] grow'>{t('location_permission')}</p>
                  {
                      userSelectedLocationPermit === true ?
                          <p className='text-xs font-semibold tracking-wide font-quickSand text-[#212121]'>{t('location_permission_on')}</p>
                          :
                          <p className='text-xs font-semibold tracking-wide font-quickSand text-[#212121]'>{t('location_permission_off')}</p>
                  }
                  <div className='ml-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <mask id="mask0_396_1603" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                              <rect width="24" height="24" fill="#D9D9D9"/>
                          </mask>
                          <g mask="url(#mask0_396_1603)">
                              <path
                                  d="M12.5998 12.0008L8.6998 8.10078C8.51647 7.91745 8.4248 7.68411 8.4248 7.40078C8.4248 7.11745 8.51647 6.88411 8.6998 6.70078C8.88314 6.51745 9.11647 6.42578 9.3998 6.42578C9.68314 6.42578 9.91647 6.51745 10.0998 6.70078L14.6998 11.3008C14.7998 11.4008 14.8706 11.5091 14.9123 11.6258C14.954 11.7424 14.9748 11.8674 14.9748 12.0008C14.9748 12.1341 14.954 12.2591 14.9123 12.3758C14.8706 12.4924 14.7998 12.6008 14.6998 12.7008L10.0998 17.3008C9.91647 17.4841 9.68314 17.5758 9.3998 17.5758C9.11647 17.5758 8.88314 17.4841 8.6998 17.3008C8.51647 17.1174 8.4248 16.8841 8.4248 16.6008C8.4248 16.3174 8.51647 16.0841 8.6998 15.9008L12.5998 12.0008Z"
                                  fill="#1C1B1F"/>
                          </g>
                      </svg>
                  </div>
              </div>
          </div>

          <div className='h-6'></div>
          <p className='text-xs font-medium tracking-wide font-quickSand underline underline-offset-1'><a
              href="https://forms.gle/49yomXDNm4NUgtJG8" target="_blank" rel="noreferrer">Tell us your feedback</a></p>


          <Drawer open={openLocationDrawer} onClose={clickLocationDrawer} placement="bottom">
              <div className='flex flex-col p-6 h-screen m-auto'>
                  <div className="flex flex-row">
                      <p className='text-xs font-normal font-quickSand grow'>{t('location_permission')}</p>
                      <p className='text-xs font-normal font-quickSand bg-clip-text text-transparent bg-gradient-to-tl from-from-blue to-to-blue border-from-blue' onClick={clickLocationDrawer}>{t('select_your_language_cancel')}</p>
                  </div>

                  <div className='h-6'></div>
                  <div className='pt-6 pb-6 flex-col w-full'>
                      <Button variant="outlined"
                              className={`h-12 w-full rounded-xl ${userSelectedLocationPermit === true ? "bg-gradient-to-tl from-from-blue/10 to-to-blue/10 border-from-blue" : ""} mb-4`}
                              ripple={false}
                              onClick={() => selectedLocation(true)}
                      >{t('location_permission_on')}</Button>
                      <Button variant="outlined"
                              className={`h-12 w-full rounded-xl mb-4 ${userSelectedLocationPermit === false ? "bg-gradient-to-tl from-from-blue/10 to-to-blue/10 border-from-blue" : ""}`}
                              ripple={false}
                              onClick={() => selectedLocation(false)}>{t('location_permission_off')}</Button>
                      <div className='h-6'></div>
                      <div className='h-6'></div>
                  </div>
              </div>
          </Drawer>

      </div>
  );
}

export default Setting;
