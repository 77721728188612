import {useCallback, useEffect, useState} from "react";
import {api} from "../../libs/api";
import Station from "../stations/Station";
import {useTranslation} from "react-i18next";
import ErrorPage from "../error/errorPage";

function KMBStations(props){
    const { i18n} = useTranslation();
    const stations = props.stopList
    const [selectedSeq, setSelectedSeq] = useState(props.defaultStop);
    const [allBusArrivalTime, setAllBusArrivalTime] = useState([]);
    const [busArrivalTime, setBusArrivalTime] = useState([]);
    const [error, setError] = useState(null);

    const route = props.route
    const direction = props.direction
    const lang = i18n.language
    const callBack = useCallback(async () =>{
        try {
            const eta = await api.getCoEtaData(route, direction, "KMB")
            const filteredETA = eta.filter(e => e.seq.toString() === selectedSeq.toString())
            setBusArrivalTime(filteredETA)
            setAllBusArrivalTime(eta)
        }catch (e) {
            throw e
        }
    }, [route, direction, selectedSeq])

    // for first loading
    useEffect(()=> {
        callBack().catch(setError)
    }, [route, direction, selectedSeq, callBack])

    // for interval fetching
    useEffect(()=>{
        const internal = setInterval(()=>{
            callBack().catch(setError)
        }, 10000);
        return () => {clearInterval(internal)};
    }, [route, direction, selectedSeq, callBack])


    const handleClick = (station) => {
        const filteredETA = allBusArrivalTime.filter(e => e.seq.toString() === station.seq)
        setBusArrivalTime(filteredETA)
        setSelectedSeq(station.seq)
    };

    if (error) {
        console.log(error)
        return <ErrorPage/>
    }

    return <Station stations={stations}
                    busArrivalTime={busArrivalTime}
                    selectedSeq={selectedSeq}
                    handleClick={handleClick}
                    lang={lang}
                    co="KMB"
                    route={route}
    />;
}

export default KMBStations
