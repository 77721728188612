import {busColor} from "../../utils/busColor";

function LineDetailHeader({co, backToPreviousPage, route}) {
    return (
        <div className='bg-white w-full' onClick={backToPreviousPage}>
            <div className="flex flex-col">
                <div className='flex flex-row items-center p-3 h-14 w-full'>
                    <i className="fa-solid fa-chevron-left text-[#6B7280]"></i>
                    <div className='pl-2'>
                        <span
                            className={`h-6 w-6 ${busColor(co)} text-font-212121 font-bold py-1 px-3 rounded-lg text-base`}>{route.route}</span>
                    </div>

                    <div className='w-full pl-2'>
                        <span className="text-black font-bold font-quickSand text-base">{route.name}</span>
                    </div>
                </div>       
            </div>
            
        </div>
    )
}

export default LineDetailHeader
