function BorderGradientButton({ children, className, onClick }) {
    return (
        <div className={`h-10 border bg-gradient-to-tl from-from-blue to-to-blue border-from-blue rounded-full p-px ${className}`} onClick={onClick}>
            <div className="flex h-full w-full bg-white rounded-full">
                {children}
            </div>
        </div>
    )
}

export default BorderGradientButton;
