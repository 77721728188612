const fontFamily = 'font-quickSand'

const BuddyFonts = {
    Caption: `${fontFamily} text-xs`,
    Body1: `${fontFamily} text-base`,
    Body2: `${fontFamily} text-base`,
    ButtonL: `${fontFamily} text-lg`,
    ButtonS: `${fontFamily} text-sm`,
    SectionTitle: `${fontFamily} text-xl`,
    SubPageTitle: `${fontFamily} text-2xl`,
    PageTitle: `${fontFamily} text-2xl`,
}

export default BuddyFonts