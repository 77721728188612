export function busColor(co){
    if (co === "KMB"){
        return "bg-kmb"
    }   else if(co === "CTB"){
        return "bg-ctb"
    } else if(co === "GMB"){
        return "bg-gmb"
    } else if (co === "NLB") {
        return "bg-nlb"
    }
}

export function busColorWithTransparent(co){
    if (co === "KMB"){
        return "bg-kmb/100"
    }   else if(co === "CTB"){
        return "bg-ctb/100"
    } else if(co === "GMB"){
        return "bg-gmb/100"
    } else if (co === "NLB") {
        return "bg-nlb/100"
    }
}