import {createContext, useState} from "react";
import {getFavoritesList, setItem} from "./LocalStorage";

const BusCollectionContext = createContext({
    busRoutes: [],
    addBusCollection: (collection) => {},
    isAddedToCollection: (co, route, stopId) => {},
    removeRoute: (collection)=>{},
    busCollectionList: () => []
});

export function BusCollectionProvider(props){
    // need to load local storage
    const [busCollection, setBusCollection] = useState(getFavoritesList())


    function busCollectionList(){
        return busCollection
    }
    function addBusCollection(collection) {
        const added = busCollection.find(bus => bus.route === collection.route && bus.co === collection.co && bus.stopId === collection.stopId)
        if (added){
            return
        }

        setBusCollection((preBusCollection) => {
            setItem('collections', [...preBusCollection, collection])
            return [...preBusCollection, collection]
        })
    }

    function isAddedToCollection(co, route, stopId){
        return busCollection.some((bus) =>{
            return bus.route === route && bus.co === co && bus.stopId === stopId
        })
    }

    function removeRoute(collection){
        if (Array.isArray(collection)){
            let newCollection = busCollection
            for (const c of collection){
                newCollection = newCollection.filter(bus => !(bus.route === c.route && bus.co === c.co && bus.stopId === c.stopId))
            }
            setBusCollection(newCollection)
            setItem('collections', newCollection)
        }else{
            const newCollection = busCollection.filter(bus => !(bus.route === collection.route && bus.co === collection.co && bus.stopId === collection.stopId))
            setBusCollection(newCollection)
            setItem('collections', newCollection)
        }
    }

    const context = {
        busRoutes: busCollection,
        addBusCollection: addBusCollection,
        isAddedToCollection: isAddedToCollection,
        removeRoute: removeRoute,
        busCollectionList: busCollectionList
    }

    return <BusCollectionContext.Provider value={context}>
        {props.children}
    </BusCollectionContext.Provider>
}

export default BusCollectionContext
