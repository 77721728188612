import {useContext, useState} from "react";
import BusLineItem from "../searchBoxList/item/BusLineItem";
import DbContext from "../../context/DbContext";
import {useTranslation} from "react-i18next";
function SearchBox(props) {
    const dbContext = useContext(DbContext)
    const routes = dbContext.routeDB;
    const [input, setInput] = useState('');
    const {t} = useTranslation();
    // eslint-disable-next-line
    let target

    const handleChange = (event) => {
        setInput(event.target.value);

        // console.log(event.target.value)
        target = routes.filter((e)=>{
            return e.route.startsWith(event.target.value)
        })

        if (event.target.value !== ""){
            props.onHidePage(false)
        }else {
            props.onHidePage(true)
        }

    }
    function sortingBus(input){
        let routesResult = []
        const first = routes.filter((route)=>{
            return route.route.toLowerCase() === input.toLowerCase()
        })
        routesResult.push(...first)
        const sec = routes.filter((route)=>{
            return route.route.toLowerCase().includes(input.toLowerCase()) && route.route.toLowerCase() !== input.toLowerCase()
        }).sort((a, b) => a.route.localeCompare(b.route))
        routesResult.push(...sec)

        return routesResult.filter((route)=> Object.keys(route.dest).length !== 0 ).map((route, index)=>{
            return <li key={index}>
                <BusLineItem
                    key={index}
                    route={ route }
                    incId={index}/>
            </li>
        })

    }

    return (
        <div className="relative rounded-full h-11 z-10">
            <input
                type="text"
                className="w-full
                 pl-10
                 pr-3
                 py-2
                 h-11
                 border-solid
                 rounded-3xl
                 bg-searchBox-grey/[.04]
                 placeholder:text-slate-400
                 font-quickSand"
                onChange={handleChange}
                value={input}
                placeholder={t('search_bus_to_collect')}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M11.1932 12.7031C10.0245 13.5788 8.57286 14.0977 7 14.0977C3.13379 14.0977 0 10.9629 0 7.09766C0 3.23242 3.13379 0.0976562 7 0.0976562C10.8662 0.0976562 14 3.23242 14 7.09766C14 8.49102 13.5928 9.78946 12.8908 10.8803C12.9304 10.9114 12.9685 10.9451 13.005 10.9815L15.5407 13.5172C16.0288 14.0054 16.0288 14.7969 15.5407 15.285C15.0525 15.7732 14.2611 15.7732 13.7729 15.285L11.2372 12.7493C11.2221 12.7342 11.2074 12.7188 11.1932 12.7031ZM12 7.09766C12 9.85938 9.76123 12.0977 7 12.0977C4.23877 12.0977 2 9.85938 2 7.09766C2 4.33594 4.23877 2.09766 7 2.09766C9.76123 2.09766 12 4.33594 12 7.09766Z"
                          fill="#212121"/>
                    </svg>
                </span>
            </div>
            <div className='pt-6'/>
            {
                input !== "" && 
                <ul className='rounded-b-lg overflow-auto bg-white'>
                    {sortingBus(input)}
                </ul>
            }
        </div>
    );
}


export default SearchBox
