function NoBorderGradientButton({ children, className, onClick }) {
    return (
        <div 
            className={`flex justify-center items-center h-10 p-2 bg-gradient-to-tl from-from-blue to-to-blue border-from-blue rounded-full w-full ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default NoBorderGradientButton;