import React from 'react';
import ErrorPage from "./errorPage";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // 你可以在这里记录错误日志
        console.error('捕获到一个错误:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage/>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;